<template>
    <div style="width: 680px">
        <header class="px-6 overflow-hidden flex gap-8 items-center border-b border-secondary-four bg-primary-three text-secondary-two" style="height: 50px">
            <div class="uppercase fs-14 font-semibold relative cursor-pointer text-primary-one">Create Plan</div>
        </header>
        <main class="p-6">
            <div>
                <h4 class="font-poppins text-primary-one fs-14">Title</h4>
                <input type="text" placeholder="Title" class="font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 mt-2 border border-secondary-five rounded" v-model="form.title">
            </div>
            <div class="mt-5">
                <h4 class="font-poppins text-secondary-one fs-14">Description</h4>
                <textarea placeholder="Description" class="font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 pt-4 h-20 mt-2 border border-secondary-five rounded" v-model="form.description"></textarea>
            </div>
            <div class="flex mt-5">
                <button class="fs-14 fw-600 font-poppins rounded bg-secondary-one text-primary-three h-10 w-28 shadow-one" @click="createPlan">Add Plan</button>
                <button class="focus:outline-none fs-14 fw-600 font-poppins i-border-1 border-secondary-one ml-5 rounded text-primary-one h-10 w-32" @click="$emit('close')">Cancel</button>
            </div>
        </main>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import axios from 'axios';
    const props = defineProps({
        projectId: Number 
    });
    const emit = defineEmits('done')
    const form = ref({
        project_id: props.projectId,
        title: '',
        description: ''
    });
    const createPlan = async () => {
       await axios.post(`/planning/store`, form.value);
       emit('done');
    }
</script>